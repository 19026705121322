<template>
    <!-- <h3>Real Time Pressure</h3> -->
    <div @contextmenu="this.setContextIdWidget" :style="{
    height: this.widgetHeight + 'px',
    width: this.widgetWidth + 'px',
      overflowy: 'hidden',
      background:
       darkDark !== 'white' 
          ? this.cardBackground
          : '#FFFFF7',
    'border-radius': '10px',
    }" :id="'svgcontainer' + widgetId">
    </div>
</template>


<script>
import * as d3 from "d3";
// import Vue from "vue";

var data = {
    soundurl: "http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3",
};
let id = null;


export default {
    name: 'numeric-progress-widget',
    sockets: {
        connect: function () {

        },
        disconnect() {
            this.isConnected = false;
            try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error('disconnect unsubscribe error ', error)
      }
        },
        reconnect() {
            this.$socket.emit("subscribe", {
                logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
                topic: this.topic,
                fields: this.fields,
                id: this.widgetId,
                clientId: this.$clientId,
            });


        },
    },
    mounted() {


        let displayId = this.displayId;
        let displayDtls = this.$store.state.disp.displays[displayId];
        if (displayDtls) {
            this.wellId = displayDtls.wellId;
            this.wellboreId = displayDtls.wellboreId;
            this.logId = displayDtls.logId;
            this.status = displayDtls.status;
            this.logType = displayDtls.logType;
            this.logTypeId = displayDtls.logTypeId;
            if (this.fullName) {
                this.displayName = this.fullName;
                this.defaultTitle = this.title;
                this.numericProgressValue = "";
                if (displayDtls.status == "UPDATING") {
                    // this.sockets.unsubscribe(this.$clientId);
                    let tempId = this.widgetId;

                    setTimeout(this.getRealtimeData, 200);
                }
                this.getTagValue(displayDtls, this.title);
            }
        }
        if (this.isBlinkAlert) {
            let i = 0;
            id = setInterval(() => {
                let color = ["black", this.alertColor];
                i = (i + 1) % color.length;
                this.newAlertColor = color[i];
                if (!this.isBlinkAlert) {
                    clearInterval(id);
                }
            }, 1000);
        }
        this.updateProgressBar("mounted");
        this.$store.subscribe(async (mutation, state) => {
            let title = this.title;

            if (mutation.type == "data/setSingleValue") {
                let display = this.$store.state.disp.displays[this.displayId];

                let valueAt = "end";
                let name =
                    title + "-" + this.displayId + "-" + display.logId + "-" + valueAt;

                let plName =
                    mutation.payload.title +
                    "-" +
                    mutation.payload.display +
                    "-" +
                    mutation.payload.logId +
                    "-" +
                    mutation.payload.valueAt;

                if (name == plName) {
                    this.numericProgressValue = mutation.payload.value;

                    if (
                        (parseInt(this.numericProgressValue + 100) > this.rangeEndDy ||
                            this.rangeEndDy - 200 > this.numericProgressValue) &&
                        this.numericProgressValue > -1
                    ) {

                        this.rangeEndDy =
                            parseInt(this.numericProgressValue + 100) -
                            (parseInt(this.numericProgressValue + 100) % 10);
                        this.defaultInterval = (this.rangeEndDy - this.rangeStartDy) / 10;
                        this.updateProgressBar(setSingleValue);
                    }
                }
            }

            if (mutation.type == "data/displayChanged") {
                let valueAt = "end";
                let name =
                    title + "-" + this.displayId + "-" + this.logId + "-" + valueAt;

                if (name == mutation.payload) {
                    this.numericProgressValue = this.$store.state.data[name].value;
                }
            }

            if (mutation.type == "disp/setDisplay" && mutation.payload.display == this.displayId) {
                this.displayName = this.fullName;
                this.defaultTitle = this.title;
                this.wellId = mutation.payload.wellId;
                this.wellboreId = mutation.payload.wellboreId;
                this.logId = mutation.payload.logId;
                this.status = mutation.payload.status;
                this.logType = mutation.payload.logType;
                this.logTypeId = mutation.payload.logTypeId;
                this.numericProgressValue = "";
                if (this.status == "UPDATING") {
                    let tempId = this.widgetId;

                    setTimeout(this.getRealtimeData, 200);
                }
                this.getTagValue(mutation.payload, this.title);
                this.updateProgressBar("mounted");

            }

            if (mutation.type == "disp/setTags") {
                if (mutation.payload.details.display == this.displayId) {
                    let unit =
                        this.$store.state.disp.displays[this.displayId].units[
                        this.$store.state.disp.displays[this.displayId].tags.indexOf(
                            this.title
                        )
                        ];

                    this.$store.dispatch("rect/changeUnit", {
                        displayId: this.displayId,
                        widgetId: this.widgetId,
                        value: unit,
                    });
                }
            }
        });

    },
    props: {
        autoFit: {
            type: Boolean,
            default: true,
        },
        isChanged: Boolean,
        blinkAlert: Boolean,
        decimalValue: String,
        borderColor: String,
        borderWidth: {
            type: Number,
            default: 1,
        },
        openConfig: Function,
        barWidgetConfig: Object,

        fullName: {
            type: String,
            default: "DIFF PRESS",
        },
        titleSize: {
            type: Number || String,
            default: "26",
        },
        alertStartRange: {
            type: Number,
            default: 0,
        },
        alertEndRange: {
            type: Number,
            default: 0,
        },
        alertColor: {
            type: String,
        },
        value: {
            type: String,
            default: "160",
        },
        valueSize: {
            type: Number || String,
            default: "32",
        },
        unit: {
            type: String,
            default: "",
        },
        unitSize: {
            type: Number || String,
            default: "30",
        },
        widgetWidth: {
            type: Number,
            default: 420,
        },
        widgetHeight: {
            type: Number,
            default: 15,
        },
        height: {
            type: Number,
            default: 15,
        },
        // barWidth: {
        //     type: Number,
        //     default: 40,
        // },
        // barHeight: {
        //     type: Number,
        //     default: 380,
        // },
        borderDisplay: {
            type: Boolean,
            default: false,
        },
        rangeStart: {
            type: Number,
            default: 0,
        },
        rangeEnd: {
            type: Number,
            default: 200,
        },
        interval: {
            type: Number,
            default: 10,
        },
        titleColor: {
            type: String,
            default: "white",
        },
        valueColor: {
            type: String,
            default: "white",
        },
        unitColor: {
            type: String,
            default: "white",
        },
        unitDisplay: {
            type: Boolean,
            default: true,
        },
        titleDisplay: {
            type: Boolean,
            default: true,
        },

        valueDisplay: {
            type: Boolean,
            default: true,
        },

        verticalcardBG: {
            default: "#FFFFF7",
        },
        lightThemeDarkColor: {
            default: "black"
        },
        id: "",
        displayId: String,
        widgetId: String,
    },
    data() {
        return {
            cardBackground: "#28282B",
            tempDark: null,
            mmValues: {},
            logTypeId: "",
            idx: -1,
            newAlertColor: this.alertColor,
            isBlinkAlert: this.blinkAlert,
            isPlayed: false,
            decimals: 2,
            widgetPropertyIndex: -1,
            defaultTitle: "",
            numericProgressValue: null,
            rangeStartDy: 0,
            rangeEndDy: 200,
            defaultInterval: 10,
            wellId: "",
            wellboreId: "",
            logId: "",
            status: "",
            logType: "",
            table: null,
            socket: null,
            displayName: "",
            title: "diff"
        };
    },
    beforeMount() {
        let rects = this.$store.state.rect.rects;
        if (typeof rects != "undefined" && rects.length > 0) {
            for (let i = 0; i < rects.length; i++) {
                if (
                    rects[i].widgetId == this.widgetId &&
                    rects[i].displayId == this.displayId
                )
                    this.widgetPropertyIndex = i;
            }
        }
        this.idx = this.widgetId;

        let displayId = this.displayId;
        let displayDtls = this.$store.state.disp.displays[displayId];
        if (displayDtls) {
            this.wellId = displayDtls.wellId;
            this.wellboreId = displayDtls.wellboreId;
            this.logId = displayDtls.logId;
            this.status = displayDtls.status;
            this.logType = displayDtls.logType;
            this.logTypeId = displayDtls.logTypeId;
            this.table = displayDtls.table;
        }
    },
    created() { },
    beforeUnmount() {
        if (this.$socket) {
            let tempId = this.widgetId;

            this.$socket.emit("unsubscribe", {
                logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
                topic: this.topic,
                id: tempId,
                clientId: this.$clientId,
            });

            this.sockets.unsubscribe(this.$clientId);
        }
    },
    methods: {
        setContextIdWidget(e) {
            this.$store.dispatch("disp/setContextId", {
                contextId: this.displayId,
                contextWidgetId: this.widgetId,
                contextType: "widget",
            });
        },
        getRealtimeData(title) {
            let test = this.widgetId;
            let topic = `${this.wellId}-${this.wellboreId}-${this.logTypeId}-${this.$store.state.data.customer}`;

            let wells = this.$store.state.data.wells;
            let well = wells[this.wellId];
            if (well) {
                let wellbore = well.wellbores[this.wellboreId];
                let log = wellbore["logs"][this.logType];
                this.logId = log.id;
                let fields = ["diff", "mm100", "mm50", "mm60", "mm75", "mm90"];

                this.fields = fields;
                let topicExists = this.$store.state.live.isSubscribed[topic];


                if (this.logId) {
                    this.$socket.emit("subscribe", {
                        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
                        topic: topic,
                        fields,
                        id: this.widgetId,
                        clientId: this.$clientId,
                        logType: this.logType,
                    });
                    if (!topicExists) {
                        this.$store.dispatch("live/setSubscriptionFlag", {
                            topic,
                            flag: true,
                        });
                    }

                    this.sockets.subscribe(this.$clientId, (sResult) => {
                        // for (let i = 0; i < socketResult.length; i++) {
                        //     let sResult = socketResult[i]
                        let common = sResult["common"];
                        let result = sResult[this.widgetId];

                        if (result) {

                                if (this.logType == "depth") {
                                    this.tooltipValue = common["dmea"];
                                }
                                if (this.logType == "time") {
                                    this.tooltipValue = new Date(
                                        parseInt(common["timestamp"])
                                    ).toLocaleString('en-US');
                                }

                                this.mmValues = result;
                                this.numericProgressValue = result[title] || result[this.title];
                                // this.numericProgressValue = 78
                                this.updateProgressBar("updatesocket")

                        }
                        // }
                    });
                }

                topicExists = this.$store.state.live.isSubscribed[topic];

            }
        },
        onDropOnWidgets(event) {
            event.stopPropagation();
            let onDropDtls = JSON.parse(event.dataTransfer.getData("elProps"));
            this.numericProgressValue = "";
            this.displayName = onDropDtls.fullName;
            this.defaultTitle = onDropDtls.title;
            this.$store.dispatch("rect/changeTitle", {
                displayId: this.displayId,
                widgetId: this.widgetId,
                value: onDropDtls.title,
                fullName: onDropDtls.fullName,
            });
            this.$store.dispatch("rect/changeUnit", {
                displayId: this.displayId,
                widgetId: this.widgetId,
                value: onDropDtls.units,
            });


            if (this.status == "UPDATING") {
                let tempId = this.widgetId;

                this.getRealtimeData(this.defaultTitle);
            }
            this.getTagValue({}, onDropDtls.title);
        },
        async getTagValue(displayDtls, title = 'diff') {
            let valueAt = "end";
            let dconfig = {};
            let wells = this.$store.state.data.wells;
            let well = wells[this.wellId];
            if (well) {
                let wellbore = well.wellbores[this.wellboreId];
                let log = wellbore["logs"][this.logType];
                this.logId = log.id;
                dconfig.field = "diff,mm100,mm50,mm60,mm75,mm90"
                dconfig.logId = this.logId;
                dconfig.table = this.table;
                if (this.logType === "depth") {
                    dconfig.logName = "depthlog";
                }
                if (this.logType === "time") {
                    dconfig.logName = "timelog";
                }
                let url = await getLatestValueUrl(dconfig);

                let result = await executeQuery(url);

                if (result != null) {
                    this.mmValues = result.data[0]
                    this.numericProgressValue = result.data[0][this.title];
                    // this.numericProgressValue = 78;
                }

                // if (
                //     (parseInt(this.numericProgressValue + 100) > this.rangeEndDy ||
                //         this.rangeEndDy - 200 > this.numericProgressValue) &&
                //     this.numericProgressValue > -1
                // ) {
                //     this.rangeEndDy =
                //         parseInt(this.numericProgressValue + 100) -
                //         (parseInt(this.numericProgressValue + 100) % 10);
                //     this.defaultInterval = (this.rangeEndDy - this.rangeStartDy) / 10;
                // }
            }
            this.updateProgressBar("getsinglevlaue");
            return this.numericProgressValue;
        },
        clearSvgHandler() {
            return new Promise((res, rej) => {
                let gauge = d3.select("#svgcontainer" + this.widgetId);
                gauge.selectAll("*").remove();
                res();
            });
        },
        playSound() {
            this.isPlayed = !this.isPlayed;
            var audio = new Audio(data.soundurl);
            audio.play();
            setTimeout(() => {
                this.isPlayed = !this.isPlayed;
            }, 12000);
        },
        updateProgressBar(from) {
            d3.select("#svgcontainer" + this.widgetId)
                .selectAll("*").remove();

            let verticalBg = this.tempDark ? "black" : this.verticalcardBG;
            let verticalCol = this.tempDark ? "white" : "black";
            let svgcontainerStyle = d3.select("#svgcontainer" + this.widgetId)
                .style("display", "flex")
                .style("align-items", "center")
                .style("justify-content", "center");

            const svg = d3
                .select("#svgcontainer" + this.widgetId)
                .append("svg")
                .attr("width", '90%')
                .attr("height", this.widgetHeight / 1.1);

            svg
                .append("text")
                .attr("id", "title" + this.widgetId)
                .attr("x", this.widgetWidth * 0.48)
                .attr("y", parseFloat(this.widgetHeight * 0.15))
                .text(this.displayName.toUpperCase() || "DIFF PRESS")
                .attr("font-size", this.autoFit
                    ? this.widgetWidth < this.widgetHeight
                        ? this.widgetWidth * 0.9 + '%'
                        : this.widgetHeight * 0.9 + '%'
                    : this.titleSize * 1.5 + '%')
                .style("font-weight", "100")
                .attr("text-anchor", "middle")
                .style("fill", verticalCol)
                .attr("class", "truncate");


            var states = []
            // segmentWidth = 100,
            for (let i in this.mmValues) {
                if (i.indexOf("mm") > -1)
                    if (this.mmValues[i] && parseFloat(this.mmValues[i]))
                        states.push(this.mmValues[i])
                    else
                        states.push(i.replace("mm", ""))
            }
            var numericProgressValue = this.numericProgressValue;
            states = states.sort((a, b) => a - b)


            let width = parseInt(this.widgetWidth);
            var xScale = d3.scaleLinear()
                .domain([0, states[states.length - 1]])
                .range([5, (this.widgetWidth / 100) * 85]);
            var x_axis = d3.axisBottom(xScale)
                .ticks(6)
                .tickSize(0);


            var colorScale = d3.scaleOrdinal()
                .domain(states)
                .range(['green', 'green', 'yellow', 'orange', 'red']);

            svg.append('rect')
                .attr('class', 'bg-rect')
                .attr('height', this.height - this.height * 0.04)
                .attr('width', '100%')
                .attr('x', 0)
                .attr('y', this.widgetHeight / 2.2 - 10)
                .attr('stroke', 'black')
                .attr('fill', 'gray');

            var progress = svg.append('rect')
                .attr('class', 'progress-rect')
                .attr('fill', function () {
                    if (xScale(numericProgressValue) <= xScale(states[0])) {
                        return "green";
                    } else if (xScale(numericProgressValue) <= xScale(states[1])) {
                        return "yellow";
                    } else if (xScale(numericProgressValue) <= xScale(states[2])) {
                        return "orange";
                    } else if (xScale(numericProgressValue) <= xScale(states[3])) {
                        return "red";
                    } else {
                        return "red";
                    }
                })
                .attr('height', parseFloat(this.height - this.height * 0.04))
                .attr('width', function () {
                    if (numericProgressValue < 0) {
                        return 0;
                    } else {
                        return xScale(numericProgressValue);
                    }
                })
                .attr('x', 0)
                .attr('y', this.widgetHeight / 2.2 - 10);

            svg.append("g")
                .style("fill", verticalCol)
                .style("font-size", (this.widgetHeight * 0.1) + 'px')
                // .attr("letter-spacing", '0.4px')
                .attr("transform", "translate(0," + (this.widgetHeight * 0.18) + ")")
                .attr('stroke-width', 0)
                .call(x_axis);

            // progress.transition()
            //     .duration(1000)
            //     .ease(d3.easeLinear)
            //     .attr('width', function () {
            //         if (numericProgressValue >= 0) {
            //             return (Math.round((numericProgressValue * 100) / 1000) > 100 ? 100 : Math.round((numericProgressValue * 100) / 1000)) + '%';
            //         } else {
            //             return 0;
            //         }
            //     });

            svg
                .append("text")
                .attr("x", this.widgetWidth * 0.48)
                .attr("id", "value" + this.widgetId)
                .attr("y", parseFloat(this.widgetHeight * 0.7))
                .attr("font-size", this.autoFit
                    ? this.widgetWidth < this.widgetHeight
                        ? this.widgetWidth * 0.9 + '%'
                        : this.widgetHeight * 0.9 + '%'
                    : this.valueSize * 1.25 + '%')
                .text(Number(this.numericProgressValue).toFixed(this.decimals || 2))
                .attr("text-anchor", "middle")
                .style("fill", verticalCol);

            svg
                .append("text")
                .attr("x", this.widgetWidth * 0.48)
                .attr("id", "unit" + this.widgetId)
                .attr("y", parseFloat(this.widgetHeight * 0.85))
                .attr("font-size", this.autoFit
                    ? this.widgetWidth < this.widgetHeight
                        ? this.widgetWidth * 0.9 + '%'
                        : this.widgetHeight * 0.9 + '%'
                    : this.unitSize * 2.0 + '%')
                .text("PSI")
                .attr("text-anchor", "middle")
                .style("fill", verticalCol);

        },
        dark() {
            this.$emit("dark");
        },
        light() {
            this.$emit("light");
        },
    },
    computed: {

        darkDark() {

            if (this.tempDark == null)
                this.tempDark = this.$store.state.rect.darkmode
            if (this.tempDark != this.$store.state.rect.darkmode) {
                this.tempDark = this.$store.state.rect.darkmode
                this.updateProgressBar("Dark dark")
            }


            if (this.tempDark)
                this.cardBackground = "#282828"
            else
                this.cardBackground = "white"

            return this.$store.state.rect.darkmode;
        },
    },
    watch: {

        isChanged: function (newVal) {
            this.updateProgressBar();
        },
        rangeStart: async function (newVal) {

            if (
                newVal + this.defaultInterval <= this.rangeEndDy ||
                newVal < this.numericProgressValue
            ) {
                this.rangeStartDy = newVal;
                await this.clearSvgHandler();
                this.updateProgressBar();
            }
        },
        rangeEnd: async function (newVal) {
            if (
                this.rangeStartDy + this.defaultInterval < newVal ||
                newVal > this.numericProgressValue
            ) {
                this.rangeEndDy = newVal;
                await this.clearSvgHandler();
                this.updateProgressBar();
            }
        },
        interval: async function (newVal) {
            if (newVal > 9 && newVal < this.rangeEndDy) {
                this.defaultInterval = newVal;
                this.updateProgressBar();
            }
        },
        blinkAlert: function (newVal) {
            this.isBlinkAlert = newVal;
            if (this.isBlinkAlert == false) {
                for (var i = 1; i < id; i++) {
                    window.clearInterval(i);
                }
                this.updateProgressBar();
            }
        },
        alertColor: async function (newVal, oldVal) {
            let i = 0;
            if (this.isBlinkAlert) {
                id = setInterval(() => {
                    let color = ["black", newVal];
                    i = (i + 1) % color.length;
                    this.newAlertColor = color[i];
                    this.updateProgressBar();

                    if (!this.isBlinkAlert) {
                        clearInterval(id);
                    }
                }, 1000);
            } else {
                this.newAlertColor = newVal;
                this.updateProgressBar();
            }
        },
        autoFit: async function (newVal, oldVal) {

            this.updateProgressBar();
        },
        titleSize: async function (newVal, oldVal) {
            this.updateProgressBar();
        },
        valueSize: async function (newVal, oldVal) {
            this.updateProgressBar();
        },
        unitSize: async function (newVal, oldVal) {
            this.updateProgressBar();
        },
        decimalValue(newValue) {
            this.decimals = newValue;
            this.updateProgressBar();
        },
        alertStartRange(newValue, value2) {
            this.updateProgressBar();
        },
        alertEndRange(newValue, value2) {
            this.updateProgressBar();
        },
        fullName(newVal) {
            this.displayName = "Diff Press";
        },
        unit(newValue, value2) {
            this.updateProgressBar();
        },
        widgetWidth(newValue, value2) {
            this.updateProgressBar("width");
        },
        widgetHeight(newValue, value2) {
            this.updateProgressBar("height");
        },
    }
};
</script>

<style scoped>
/* #tooltip {
    position: absolute;
    margin: auto;
    font-size: 12px;
    line-height: 10px;
    color: white;
    border: 1px dotted black;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.78);
    width: 100%;
    top: 25%;
    height: 20px;
    text-align: center;
} */
</style>